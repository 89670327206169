.hero {
	/* TODO: update hero url if necessary */
	background-image: url('../../assets/images/hero.png');
	background-size: cover;
	width: 100%;
	height: 70vh;
	margin-top: -76px;
}

.logoOverlay {
	padding-top: 10%;
	max-width: 50%;
	filter: drop-shadow(0px 0px 5px dimgray);
}

.tagline {
	font-size: larger;
	filter: drop-shadow(0px 0px 5px dimgray);
}

/* tablets */
@media screen and (min-width: 320px) and (max-width: 920px) {
	.hero {
		/* TODO: update heroSm url if necessary */
		background-image: url('../../assets/images/heroSm.png');
		background-size: cover;
	}
}

@media screen and (min-width: 425px) and (max-width: 767px) {
	.logoOverlay {
		padding-top: 10%;
		max-width: 100%;
	}
}
/* phone screens */
@media screen and (min-width: 320px) and (max-width: 424px) {
	.logoOverlay {
		padding-top: 30%;
		max-width: 100%;
	}
	.tagline {
		padding-left: 8%;
	}
}
