.navBackgroundTransparent {
	background-color: transparent;
	padding: 8;
}
/* TODO: Customize background color to match client branding */
.navBackgroundColor {
	background-color: #ffe89d;
	padding: 8;
}

.navbar-brand {
	margin-left: 10px;
}

/* TODO: Customize background color to match client branding */
.show,
.collapsing {
	background-color: #ffe89d;
}
