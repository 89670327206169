.footer {
	clear: both;
	background-color: transparent;
	width: 100%;
	text-align: center;
	padding-bottom: 5px;
	font-family: 'Spartan', sans-serif;
}

.footer a {
	/* TODO: update color to match client branding */
	color: #ffffff;
	text-decoration: none;
}

.footer a:hover {
	color: #b2a2b9;
	text-decoration: underline;
	/* TODO: uncomment below to add easter egg pointer (max dimensions 32x32px) */
	cursor: url(../../assets/images/egg.png), pointer;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
	.footer {
		padding-bottom: 15px;
	}
}
