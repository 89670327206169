.notFound {
  padding-top: 15%;
  height: 100vh;
  width: 100vw;
}
.imageCenter {
  display: flex-row;
  justify-content: center;
  align-items: center;
}
