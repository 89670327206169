/* Color Story */

/* TODO: Update all fields with client branding */
h1,
h2,
h3,
h4 {
	filter: drop-shadow(0px 0px 5px #f7b56a);
}

.pageView {
	background-color: #fff4ce;
	font-family: 'Nunito', san-serif;
	color: black;
}
.title-text {
	filter: drop-shadow(0px 0px 5px #f7b56a);
	display: flex;
	justify-content: center;
	font-size: 400%;
}

.image-container {
	display: flex;
	justify-content: center;
	align-items: center;
	filter: drop-shadow(5px 5px 15px #f8b117);
}

.section-container {
	margin-top: 40px;
}

span.anchor {
	margin-top: -76px; /* height of nav */
	display: block;
	height: 76px; /* height of nav */
	visibility: hidden;
	position: relative;
}

a {
	/* grows all links on hover */
	transition: all 0.2s ease-in-out;
}
a:hover {
	transform: scale(1.1);
}

/* mobile screens */
@media screen and (min-width: 320px) and (max-width: 767px) {
	.section-container {
		padding-bottom: 10px;
	}
	a {
		transition: unset;
	}
	a:hover {
		transform: unset;
	}
	.title-text {
		font-size: 300%;
	}
}
